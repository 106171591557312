/* eslint-disable camelcase */
import React from 'react'
import { Helmet } from 'react-helmet'
import Config from '../../../config'
import Layout from '../../../components/layoutDevelopers'

const REDIRECT_URL = `${Config.DEVELOPERS_PORTAL_URL}/reference/merchant-api/v1`

export default function ApiV1_0 () {
  return (
    <Layout>
      <section>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col text-center'>
            <div className="inner">
              <Helmet>
                <meta httpEquiv='refresh' content={`0; url=${REDIRECT_URL}`} />
                <link rel='canonical' href={REDIRECT_URL} />
              </Helmet>
              <h1>Trexity API v1.0</h1>
              <p>This page has moved. This page will be redirected in a few seconds.</p>
              <a className='button-link button-link-brand' href={REDIRECT_URL}>Navigate To Page Now</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
